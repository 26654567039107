<template>
  <div class="unStored_content">
    <span
      class="suc"
      :style="{
        width: successWidth + '%',
        minWidth: minWidth(statusData.success),
      }"
      >{{ statusData.success }}</span
    >
    <span
      class="ongoing"
      :style="{
        width: ongoingWidth + '%',
        minWidth: minWidth(statusData.ongoing),
      }"
      >{{ statusData.ongoing }}</span
    >
    <span
      class="failure"
      :style="{
        width: failureWidth + '%',
        minWidth: minWidth(statusData.failure),
      }"
      >{{ statusData.failure }}</span
    >
  </div>
</template>

<script>
export default {
  name: "UnstoredStatus",
  data() {
    return {};
  },
  props: {
    statusData: {
      type: Object,
      default() {
        return {
          success: 857,
          ongoing: 1,
          failure: 55,
        };
      },
    },
  },
  computed: {
    totalNum() {
      let res = 0;
      Object.values(this.statusData).forEach((v) => (res += v));
      return res;
    },
    successWidth() {
      return this.statusData.success === 0
        ? 0
        : Math.floor((this.statusData.success / this.totalNum) * 100);
    },
    ongoingWidth() {
      return this.statusData.ongoing === 0
        ? 0
        : Math.floor((this.statusData.ongoing / this.totalNum) * 100);
    },
    failureWidth() {
      return this.statusData.failure === 0
        ? 0
        : 100 - this.successWidth - this.ongoingWidth;
    },
  },
  methods: {
    minWidth(width) {
      return width ? (width + "").length * 5 + "%" : 0;
    },
  },
};
</script>

<style scoped lang="scss">
.unStored_content {
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  color: #fff;

  .suc {
    background-color: #67c23a;
  }

  .ongoing {
    background-color: #e6a23c;
  }

  .failure {
    background-color: #f56c6c;
  }
}
</style>
