<template>
  <div class="colWrapper">
    <div style="margin-bottom: 10px">
      <div class="title_block">
        <p>活动达人管理</p>
      </div>
      <div class="form">
        <div class="form_title">
          <p>收藏设置</p>
          <div style="display: flex;justify-content: flex-start;align-items: center;gap:10px;">
            <div>
              <svg-icon
                  icon-class="createNewIcon"
                  class-name="createNewIcon"
              ></svg-icon>
              <el-button
                  class="noborderBtn"
                  type="text"
                  style="margin-right:0;color:#000;"
                  @click="create('ruleForm')"
              >
                创建收藏
              </el-button>
            </div>
            <div>
              <svg-icon
                  icon-class="resetIcon"
                  class-name="resetIcon"
              ></svg-icon>
              <el-button
                  class="noborderBtn"
                  type="text"
                  style="margin-right:0;color:#000;"
                  @click="resetForm('ruleForm')"
              >
                重置
              </el-button>
            </div>
          </div>
        </div>
        <el-form
            ref="ruleForm"
            :rules="colRules"
            :model="form"
            label-width="50px"
            label-position="top"
        >
          <el-row>
            <el-col :span="6">
              <el-form-item prop="name" label="名称">
                <el-input v-model="form.name"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6" :offset="1">
              <el-form-item prop="desc" label="说明">
                <el-input v-model="form.desc"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
    <div>
      <div class="form_title" style="padding:0 20px;">
        <p>收藏列表</p>
        <div style="display: flex;justify-content: flex-start;align-items: center;gap:10px;">
          <el-input
              class="search"
              placeholder="请输入搜索内容"
              @change="getUserCollect"
              @clear="getUserCollect"
              v-model="searchWord"
              clearable
          >
            <template #suffix>
              <el-icon><Search /></el-icon>
            </template>
          </el-input>
          <div class="amount" v-show="colTotal !== 'inf'">
            目前使用: {{ collected }} , 总额度: {{ colTotal }}
          </div>
        </div>
      </div>
      <el-table
        style="margin-top: 20px"
        v-loading="tableLoading"
        :height="tableHeight"
        :data="tableData"
        :header-cell-style="headerStyle"
        :cell-style="cellStyle"
      >
        <el-table-column align="center" prop="order" label="序号" width="80">
        </el-table-column>

        <el-table-column align="center" prop="name" label="名称">
          <template #default="{ row }">
            <span
              style="cursor: pointer; color: #51a6f0"
              @click="clickDetail(row)"
            >
              {{ row.name }}
            </span>
          </template>
        </el-table-column>

        <el-table-column
          align="center"
          prop="creator"
          label="创建者"
          width="100"
        >
        </el-table-column>

        <el-table-column
          prop="date"
          label="创建时间"
          align="center"
          label-class-name="colActClass"
          class-name="colActClass"
        >
          <template #header="{ column }">
            <span class="clickClass" @click="sortClick">
              {{ column.label }}
            </span>
          </template>
          <template #default="{ row }">
            <span style="font-size: 12px">{{ row.date }}</span>
          </template>
        </el-table-column>

        <el-table-column
          align="center"
          prop="status"
          label="7天添加KOL入库状态"
        >
          <template #header>
            <el-tooltip class="item" effect="dark" placement="top">
              <template #content>
                <div>
                  <i class="topStatus suc" style="margin-right: 5px"></i>成功
                  <i class="topStatus ongoing" style="margin-right: 5px"></i
                  >进行中
                  <i class="topStatus fail_col" style="margin-right: 5px"></i
                  >失败
                </div>
              </template>
              <span>7天添加KOL入库状态</span>
            </el-tooltip>
          </template>
          <template #default="{ row }">
            <unstored-status
              v-if="
                row.unstoredKolStatus.success !== 0 ||
                row.unstoredKolStatus.ongoing !== 0 ||
                row.unstoredKolStatus.failure !== 0
              "
              :statusData="row.unstoredKolStatus"
            />
            <div style="height: 50px; line-height: 50px" v-else>
              暂无更新数据
            </div>
          </template>
        </el-table-column>

        <el-table-column
          align="center"
          prop="kolCount"
          label="KOL数量"
          width="100"
        >
        </el-table-column>

        <el-table-column align="center" prop="desc" label="说明" width="200">
        </el-table-column>

        <el-table-column align="center" label="操作" width="120">
          <template #default="{ row }">
            <el-tooltip
              class="item"
              effect="dark"
              content="删除"
              placement="top"
            >
              <svg-icon
                class-name="iconshanchu"
                class="i"
                @click="delCol(row)"
              />
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="详情"
              placement="top"
            >
              <svg-icon
                class-name="iconlvzhou_gengduo_xiangqing"
                class="i"
                style="margin-left: 10px"
                @click="clickDetail(row)"
              />
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <div class="articlePaginationBox">
        <el-pagination
          :page-size="pageSize"
          :current-page="currentPage"
          @current-change="handleCurrentChange"
          layout="total, prev, pager, next, jumper"
          :total="totalSize"
        >
        </el-pagination>
        <div class="amount" v-show="colTotal !== 'inf'">
          目前使用: {{ collected }} , 总额度: {{ colTotal }}
        </div>
      </div>
    </div>
    <el-dialog title="警告" v-model="dialogVisible" width="30%">
      <span>确定要删除此收藏夹吗？</span>
      <template #footer>
        <div>
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="realDel">确 定</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import mainTitle from "@/components/common/mainTitle";
import unstoredStatus from "@/views/KolCollection/unstoredStatus";
import {
  creatCollect,
  getUserCollect,
  delCollect,
  getAmount,
} from "@/api/collection";

export default {
  data() {
    const checkColName = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("名称不能为空"));
      }
      let regular = new RegExp(
        "[`·~!@#$^&*()=+|{}':;',\\[\\].<>《》/?~！@#￥……&*（）——|{}【】 ‘；：”“'。，、？]"
      );
      setTimeout(() => {
        if (regular.test(value) || value.replace(/\s+/g, "") === "") {
          callback(new Error("任务名称只能包含数字、字母、中文汉字、_、-"));
        } else {
          callback();
        }
      }, 200);
    };
    return {
      // 表格样式
      headerStyle: {
        backgroundColor: "#F9FCFE",
        borderTopWidth: "1px",
        borderTopColor: "#eee",
        borderTopStyle: "solid",
      },
      cellStyle: {
        fontSize: "12px",
      },
      searchWord: "",
      form: {
        name: "",
        desc: "",
      },
      colRules: {
        name: [
          { validator: checkColName, trigger: "blur" },
          { required: true, message: "请输入收藏夹名称", trigger: "blur" },
          {
            min: 1,
            max: 50,
            message: "长度在 1 到 50 个字符",
            trigger: "blur",
          },
        ],
        desc: [
          { message: "请填写收藏夹描述" },
          { max: 500, message: "长度小于500字符", trigger: "blur" },
        ],
      },
      tableData: [],
      currentPage: 1,
      pageSize: 5,
      totalSize: 0,
      tableLoading: true,
      colTimeSort: 0,
      colId: 0,
      dialogVisible: false,
      colTotal: 0,
      collected: 0,
      tableHeight:1200
    };
  },
  mounted() {
    this.getUserCollect();
    this.getAmount();
  },
  computed:{
    tableHeight() {
      return  document.body.clientHeight - 520;
    },
  },
  methods: {
    async getUserCollect() {
      this.tableLoading = true;
      let params = {
        search: this.searchWord.replace(/\s+/g, ""),
        page: this.currentPage,
        size: this.pageSize,
        sortColumn: "create_time",
        sortDirection: this.colTimeSort === 0 ? "desc" : "asc",
      };
      let res = await getUserCollect(params);
      if (res.code === 0) {
        this.tableData = res.data.table;
        this.tableData.forEach((v, i) => {
          v.order = ++i;
          v.kolCount = Object.values(v.kolNum).reduce((prev, curr) => {
            return prev + curr;
          });
        });
        this.totalSize = res.data.total;
      }
      this.tableLoading = false;
    },
    async getAmount() {
      let res = await getAmount();
      if (res.code === 0) {
        this.colTotal = res.data.total;
        this.collected = res.data.collected;
      }
    },
    create(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let { code } = await creatCollect(this.form);
          if (code === 0) {
            this.$message({
              message: "创建收藏夹成功！",
              type: "success",
            });
            await this.getUserCollect();
            await this.getAmount();
          } else {
            this.$message.error("创建失败!");
          }
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    handleCurrentChange(cur) {
      console.log(cur);
      this.currentPage = cur;
      this.getUserCollect();
    },
    clickDetail(row) {
      this.$storage.setItem("activeName", row.name);
      this.$router.push({
        name: "CollectDetail",
        query: { collectionId: this.btoa(row.id) },
      });
    },
    sortClick() {
      this.colTimeSort = this.colTimeSort ^ 1;
      this.currentPage = 1;
      this.getUserCollect();
    },
    // 删除任务
    delCol(row) {
      this.dialogVisible = true;
      this.colId = row.id;
    },
    async realDel() {
      let { code } = await delCollect(this.colId);
      if (code === 0) {
        let colIndex = this.tableData.findIndex((v) => {
          return v.id === this.colId;
        });
        this.tableData.splice(colIndex, 1);
        this.dialogVisible = false;
        this.$message({
          message: "删除收藏夹成功!",
          type: "info",
        });
        await this.getUserCollect();
        await this.getAmount();
      } else {
        this.$message.error("数据加载失败，请重新加载");
      }
    },
  },
  components: {
    mainTitle,
    unstoredStatus,
  },
};
</script>

<style scoped lang="scss">
.colWrapper {
  background-color: #fff;
  height:100%;
  border-radius: 20px;
  .form {
    background-color: rgba(249, 248, 248, 0.73);
    padding:10px 20px;
  }

  .articlePaginationBox {
    display: flex;
    justify-content: center;
    margin-top:10px;
  }

  .i {
    cursor: pointer;
    font-size: 14px;
  }
}

.topStatus {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 50%;
  margin-right: 5px;
}

.suc {
  background-color: #67c23a;
}

.ongoing {
  background-color: #e6a23c;
}

.fail_col {
  background-color: #f56c6c;
}
.title_block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 72px;
  border-bottom: 1px solid #EDEDED;
  padding: 0 20px;
  p{
    font-weight: 500;
    font-size:22px;
    display: flex;
    align-items: center;
    gap:20px;
    .dataTime{
      color:#666;
      font-size:14px;
    }
  }
}
.form_title{
  display: flex;
  justify-content: space-between;
  align-items: center;
  p{
    font-family: PingFang SC;
    font-size: 16px;
    font-weight: 500;
    line-height: 22.4px;
    color: #222;
  }
}

</style>
<style lang="scss">
.search {
  width: 250px;
  .el-input__inner{
    background:rgba(218,218,218,0.13)!important;
    border-radius:8px;
    height:32px;
    line-height: 32px;
  }
}
</style>
